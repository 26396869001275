import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBABRnVh7up4xh2aNf2uVcKc6hnRlOZzfw",
  authDomain: "muvin.it", // prod: muvin.it. debug: muvin-4238c.firebaseapp.com
  projectId: "muvin-4238c",
  storageBucket: "muvin-4238c.appspot.com",
  messagingSenderId: "672304757697",
  appId: "1:672304757697:web:74ff7c29223c4e5edb4d40"
};

initializeApp(firebaseConfig);

new Vue({
  render: h => h(App),
}).$mount('#app')
