/* eslint-disable */
<template>
    <div id="login">
         <!-- <card style="max-width: 40rem;" title="muvin BackOffice" class="mt-5"> -->
            <div>
                <input
                    id="input-1"
                    v-model="input.username"
                    placeholder="Username"
                >
                <input
                    id="input-2"
                    v-model="input.password"
                    placeholder="Password"
                    type="password"
                >
                <div class="mt-2">
                    <button role="button" variant="primary" @click="login()">Login</button>
                    <button @click="googleSignIn()">
                        Sign In with Google
                    </button>
                    <button @click="googleSignInRedirect()">
                        Sign In Redirect with Google
                    </button>
                    <button @click="appleSignIn()">
                        Sign In with Apple
                    </button>
                    <button @click="appleSignInRedirect()">
                        Sign In Redirect with Apple
                    </button>
                    <button @click="facebookSignInRedirect()">
                        Sign In Redirect with Facebook
                    </button>
                    <button variant="danger" @click="reset" class="ml-2">Reset</button>
                </div>
            </div>
            <div>{{ token2 }}</div>
            <div>{{ email }}</div>
            <div>{{ uuid }}</div>
        <!-- </card> -->
    </div>
</template>

<script>
    import { getAuth, getRedirectResult, signInWithPopup, signInWithEmailAndPassword, 
    signInWithRedirect, GoogleAuthProvider, OAuthProvider, FacebookAuthProvider } from "firebase/auth";
    // import axios from 'axios';
    
    export default {
        name: 'Login',
        data() {
            return {
                token: "",
                token2: "",
                email: "",
                uuid: "",
                muvinws_base_url: "",
                input: {
                    username: "",
                    password: ""
                }
            }
        },
        mounted: function() {
            this.initApp();
        },
        methods: {
            reset() {
                this.input.username = ""; 
                this.input.password = "";
            },
            login() {
                const auth = getAuth();
                signInWithEmailAndPassword(auth, this.input.username, this.input.password)
                .then((error) => {
                    console.error(error);
                });
            },
            googleSignIn() {
                const provider = new GoogleAuthProvider();
                provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
                const auth = getAuth();
                signInWithPopup(auth, provider)
                .then((result) => {
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    const credential = GoogleAuthProvider.credentialFromResult(result);
                    const token = credential.accessToken;
                    // The signed-in user info.
                    const user = result.user;
                    console.log('token: '+ token);
                    console.log('user: ' + user);
                    console.log('user_email: ' + user.email);
                    console.log('user_uuid: ' + user.uid);
                }).catch((error) => {
                    console.error(error);
                });
            },
            googleSignInRedirect() {
                console.log('currentUser: ' + getAuth().currentUser);
                if (!getAuth().currentUser) {
                    const provider = new GoogleAuthProvider();
                    provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
                    const auth = getAuth();
                    signInWithRedirect(auth, provider);
                } else {
                    // console.log('currentUser: ' + getAuth().currentUser);
                    // getAuth().currentUser.getIdToken(true).then((idToken) => {
                    //     console.log('token2: '+ idToken);
                    // })

                    getAuth().signOut();
                    location.reload();
                }
            },
            appleSignIn() {
                const provider = new OAuthProvider('apple.com');
                provider.addScope('email');
                provider.addScope('name');
                // Result from Redirect auth flow.
                const auth = getAuth();
                signInWithPopup(auth, provider)
                .then((result) => {
                    // The signed-in user info.
                    const user = result.user;
                    const credential = OAuthProvider.credentialFromResult(result);
                    const idToken = credential.idToken;

                    console.log('token: ' + idToken);
                    console.log('user: ' + user);
                    console.log('user_email: ' + user.email);
                    console.log('user_uuid: ' + user.uid);
                })
                .catch((error) => {
                    console.error(error);
                });
            },
            appleSignInRedirect() {
                if (!getAuth().currentUser) {
                    const provider = new OAuthProvider('apple.com');
                    provider.addScope('email');
                    provider.addScope('name');
                    // Result from Redirect auth flow.
                    const auth = getAuth();
                    signInWithRedirect(auth, provider);
                } else {
                    getAuth().signOut();
                    location.reload();
                }
            },
            facebookSignInRedirect() {
                if (!getAuth().currentUser) {
                    const provider = new FacebookAuthProvider();
                    provider.addScope('user_likes');
                    const auth = getAuth();
                    signInWithRedirect(auth, provider);
                } else {
                    getAuth().signOut();
                    location.reload();
                }
            },
            initApp() {
                // Result from Redirect auth flow.
                const auth = getAuth();
                getRedirectResult(auth).then((result) => {
                    var credential = GoogleAuthProvider.credentialFromResult(result);
                    if (!credential)
                        credential = OAuthProvider.credentialFromResult(result);    
                    
                    const token = credential.idToken;
                    // The signed-in user info.
                    const user = result.user;

                    this.token = token;
                    this.email = user.email;
                    this.uuid = user.uid;

                    console.log('token: '+ token);
                    console.log('user: ' + user);
                    console.log('user_email: ' + user.email);
                    console.log('user_uuid: ' + user.uid);
                })
                .catch(() => {
                    // console.error(error);
                });

                // Listening for auth state changes.
                auth.onAuthStateChanged((user) => {
                    if (user) {
                        user.getIdToken().then((idToken) => {
                            this.token2 = idToken;
                            console.log('token2: '+ idToken);
                        })  
                    }
                });

            }
        }
    }
</script>

<style scoped>
    #login {
        width: 500px;
        border: 1px solid #CCCCCC;
        background-color: #FFFFFF;
        margin: auto;
        margin-top: 200px;
        padding: 20px;
    }
</style>